import React from 'react';
import moment from 'moment-timezone';

class SearchSelections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedBillNumbers: props.selectedBillNumbers,
            selectedBillRangeBeginning: props.selectedBillRangeBeginning,
            selectedBillRangeEnd: props.selectedBillRangeEnd,
            selectedChapterNumber: props.selectedChapterNumber,
            selectedKeywords: props.selectedKeywords,
            currentStatus: props.currentStatus,
            selectedBillStatusCategory: props.selectedBillStatusCategory,
            selectedSession: props.selectedSession,
            billStatusStartDate: props.billStatusStartDate,
            billStatusEndDate: props.billStatusEndDate,
            selectedCommittee: props.selectedCommittee,
            selectedSubcommittee: props.selectedSubcommittee,
            selectedPatron: props.selectedPatron,
            selectedPatronType: props.selectedPatronType,
            selectedChamber: props.selectedChamber,
            selectedBillType: props.selectedBillType,
            selectedSubject: props.selectedSubject,
            excludeFailed: props.excludeFailed,
            disabled: false
        };
        this.interceptChange = this.interceptChange.bind(this);
    };

    componentDidUpdate(prevProps) {
        if (prevProps.isFetching !== this.props.isFetching) {
            this.setState({
                ...this.props,
                disabled: this.props.isFetching
            });
        }
    }

    interceptChange(fcn) {
        this.setState({ disabled: true });
        fcn();
    }

    render() {
        return (
            <div className="search-selections">
                <span className="txt-greyed small-text">Your Search</span>
                <div>
                    <Selection
                        value={this.state.selectedBillNumbers}
                        name={"selectedBillNumbers"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedBillRangeBeginning}
                        name={"selectedBillRangeBeginning"}
                        title="Bill Range Start"
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedBillRangeEnd}
                        name={"selectedBillRangeEnd"}
                        title="Bill Range End"
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedChapterNumber}
                        name={"selectedChapterNumber"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedKeywords}
                        name={"selectedKeywords"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.currentStatus && this.state.selectedBillStatusCategory ? this.state.selectedSession && !this.state.selectedSession.IsActive ? 'Final Status' : 'Current Status' : null}
                        name={"currentStatus"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedBillStatusCategory ? this.state.selectedBillStatusCategory.label : null}
                        name={"selectedBillStatusCategory"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.billStatusStartDate ? moment(this.state.billStatusStartDate).format("MM/DD/YYYY") : null}
                        name={"billStatusStartDate"}
                        title="Status Start Date"
                        clear={null}
                        disabled={true}
                    />
                    <Selection
                        value={this.state.billStatusEndDate ? moment(this.state.billStatusEndDate).format("MM/DD/YYYY") : null}
                        name={"billStatusEndDate"}
                        title="Status End Date"
                        clear={null}
                        disabled={true}
                    />
                    <Selection
                        value={this.state.selectedCommittee ? this.state.selectedCommittee.label : null}
                        name={"selectedCommittee"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedSubcommittee ? this.state.selectedSubcommittee.label : null}
                        name={"selectedSubcommittee"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedPatron ? this.state.selectedPatron.label : null}
                        name={"selectedPatron"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    {this.state.selectedPatronType.map((type, typeIndex) =>
                        <Selection
                            key={typeIndex}
                            index={typeIndex}
                            value={type.label}
                            name={"selectedPatronType"}
                            clear={() => this.interceptChange(() => this.props.handleMultiSelectionClear("selectedPatronType", typeIndex, true))}
                            disabled={this.state.disabled}
                        />
                    )}
                    <Selection
                        value={this.state.selectedChamber ? this.state.selectedChamber.label : null}
                        name={"selectedChamber"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedBillType ? this.state.selectedBillType.label : null}
                        name={"selectedBillType"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.selectedSubject ? this.state.selectedSubject.label : null}
                        name={"selectedSubject"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                    <Selection
                        value={this.state.excludeFailed ? "Exclude Failed" : null}
                        name={"excludeFailed"}
                        clear={e => this.interceptChange(() => this.props.handleSelectionClear(e))}
                        disabled={this.state.disabled}
                    />
                </div>
                <hr className="faded-line" />
            </div>
        )
    }
}

const Selection = props => {
    const { title, value, name, index, clear, disabled } = props;
    if (value) {
        return (<div className={"selection-bubble " + (disabled ? 'disabled' : '')}>
            <div className="flex-row" onClick={() => !disabled && clear(name, index)}>
                <span>{title ? `${title}: ${value.length > 100 ? value.substring(0, 100) + '...' : value}` : value.length > 100 ? value.substring(0, 100) + '...' : value}</span>
                {clear && <button aria-label="remove item" onClick={(e) => { e.preventDefault() }} className={"icon " + (disabled ? 'cross-grey' : 'cross-blue')} disabled={disabled}></button>}
            </div>
        </div>);
    } else {
        return (false);
    }

}

export default SearchSelections;
