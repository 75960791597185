import { createBrowserHistory } from "history";
import { publicRequest } from "../services/request.service";

const previousLocationRequest = "REQUEST_PREVIOUS_LOCATION";
const changeSessionRequest = "CHANGE_SESSION";
const makeToastRequest = "MAKE_TOAST";
const toggleOverlayRequest = "TOGGLE_OVERLAY"
const fileSuccess = "FILE_SUCCESS";
const fileFailure = "FILE_FAILURE";
const reloadSessionSelectorChange = "RELOAD_SESSION_SELECTOR_CHANGE";

const history = createBrowserHistory;

const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const initialState = {
    currentLocation: history.location,
    previousLocation: '',
    session: '',
    toasts: [],
    hideSessionSelectorPaths: [
        '/profile',
        '/developers',
        'change-password',
        'passwordreset',
        '/forgot-password',
        '/register-account',
        '/committee-management',
        '/session-management',
        '/docket-management',
        '/calendar-management',
        '/minutes-management',
        '/journal-management',
        '/communications-management',
        '/configurations-management',
        '/version-management',
        '/bill-search-report',
        '/bill-details',
        '/vote-details',
        '/vote-management',
        '/vote-search',
        '/member-management',
        '/print-management',
        '/pdf',
        '\/calendar\/[0-9]+',
        '/member-legislation',
        'notifications',
        '/apiregistration',
        '/acts-management'
    ],
    showOverlay: false
};

export const navActionCreators = {
    getPreviousLocation: (previousLocation) => ({
        type: previousLocationRequest,
        previousLocation: previousLocation
    }),

    changeSession: (session) => ({
        type: changeSessionRequest,
        session: session
    }),

    makeToast: (message) => ({
        type: makeToastRequest,
        toasts: message
    }),

    toggleOverlay: (overlay) => ({
        type: toggleOverlayRequest,
        showOverlay: overlay
    }),

    receiveFile: (result) => ({
        type: fileSuccess,
        file: result
    }),

    fileError: (message) => ({
        type: fileFailure,
        fileError: message
    }),

    getFile(url) {
        return dispatch => {
            let config = {
                method: 'GET',
                headers: { "WebAPIKey": process.env.REACT_APP_API_KEY }
            }
            return fetch(url, config)
                .then(response => {
                    if (response.status === 204) {
                        dispatch(navActionCreators.fileError('No Content'));
                        return Promise.reject('No Content');
                    } else if (response.status > 399) {
                        return (response.text()
                            .then(text => {
                                dispatch(navActionCreators.fileError(text));
                                return Promise.reject(text)
                            })
                        )
                    }
                    return response.text();
                })
                .then((data) => {
                    dispatch(navActionCreators.receiveFile(data));
                }).catch(err => {
                    console.log(err);
                    return Promise.reject(err);
                });
        }
    },

    reloadSessionSelector: (reload) => ({
        type: reloadSessionSelectorChange,
        reloadSessionSelector: reload
    })
};

export const reducer = (state, action) => {
    state = state || initialState;
    switch (action.type) {
        case previousLocationRequest:
            return Object.assign({}, state, {
                previousLocation: action.previousLocation
            });
        case changeSessionRequest:
            return Object.assign({}, state, {
                session: action.session
            });
        case makeToastRequest:
            return Object.assign({}, state, {
                toasts: action.toasts
            });
        case toggleOverlayRequest:
            return Object.assign({}, state, {
                showOverlay: action.showOverlay
            });
        case fileSuccess:
            return Object.assign({}, state, {
                file: action.file
            });
        case fileFailure:
            return Object.assign({}, state, {
                fileError: action.fileError
            });
        case reloadSessionSelectorChange:
            return Object.assign({}, state, {
                reloadSessionSelector: action.reloadSessionSelector
            });
        default:
            return state;
    }
};
