import { partnerRequest } from '../services/request.service'

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const actsCategoryListRequest = 'ACTS_CATEGORY_LIST_REQUEST';
const actsCategoryListSuccess = 'ACTS_CATEGORY_LIST_SUCCESS';
const actsCategoryListFailure = 'ACTS_CATEGORY_LIST_FAILURE';

const actsChapterNumbersListRequest = 'ACTS_CHAPTER_NUMBERS_LIST_REQUEST';
const actsChapterNumbersListSuccess = 'ACTS_CHAPTER_NUMBERS_LIST_SUCCESS';
const actsChapterNumbersListFailure = 'ACTS_CHAPTER_NUMBERS_LIST_FAILURE';

const actsAppendixBuildSuccess = 'ACTS_APPENDIX_BUILD_SUCCESS';
const actsAppendixBuildFailure = 'ACTS_APPENDIX_BUILD_FAILURE';

const actsChaptersBuildSuccess = 'ACTS_CHAPTERS_BUILD_SUCCESS';
const actsChaptersBuildFailure = 'ACTS_CHAPTERS_BUILD_FAILURE';

const actsChaptersRangeBuildSuccess = 'ACTS_CHAPTERS_RANGE_BUILD_SUCCESS';
const actsChaptersRangeBuildFailure = 'ACTS_CHAPTERS_RANGE_BUILD_FAILURE';

const actsResolutionsBuildSuccess = 'ACTS_RESOLUTIONS_BUILD_SUCCESS';
const actsResolutionsBuildFailure = 'ACTS_RESOLUTIONS_BUILD_FAILURE';

const initialState = {
    actsCategoryList: [],
    actsCategoryMessage: '',
    actsChapterNumbersList: [],
    actsChapterNumbersMessage: '',
    actsAppendixBuild: [],
    actsAppendixBuildError: '',
    actsChaptersData: [],
    actsChaptersBuildError: '',
    actsChaptersRangeData: [],
    actsChaptersRangeBuildError: '',
    actsResolutionsData: [],
    actsResolutionsBuildError: '',
    isFetching: false
};

export const actsActionCreators = {
    // GET ACTS CATEGORY LIST FOR ACTS MGMT GRID
    requestActsCategoryList: () => ({
        type: actsCategoryListRequest,
        actsCategoryList: [],
        isFetching: true
    }),

    receiveActsCategoryList: (list, message) => ({
        type: actsCategoryListSuccess,
        isFetching: false,
        actsCategoryList: list,
        actsCategoryMessage: message
    }),

    actsCategoryListError: (message) => ({
        type: actsCategoryListFailure,
        isFetching: false,
        actsCategoryList: [],
        actsCategoryMessage: message
    }),

    getActsCategories: () => {
        return dispatch => {
            dispatch(actsActionCreators.requestActsCategoryList());

            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            // return partnerRequest((API_URL || process.env.REACT_APP_JOURNAL_API_URL) + '/ActsFileGeneration/api/GetActsCategoryTypes', 'GET', headers, '', '')
            //     .then(result => {
            //         if (result[0] === REQUEST_FAILURE) {
            //             dispatch(actsActionCreators.actsCategoryListError(result[1]))
            //             return Promise.reject(result[1])
            //         } else if (result[0] === REQUEST_SUCCESS) {
            //             // Dispatch the success action
            //             dispatch(actsActionCreators.receiveActsCategoryList(result[1].ActsCategoryTypes.map(x => { return { label: x.Name, value: x.ActsCategoryTypeID } })))
            //         } else if (result[0] === NO_CONTENT) {
            //             dispatch(actsActionCreators.receiveActsCategoryList([]));
            //         } else if (result.name === 'AbortError') {
            //             return Promise.reject("Aborted");
            //         }
            //     }).catch(err => Promise.reject(err))

            // return new Promise((resolve, reject) => {
            //     return resolve([{label: 'Vetoes', value: 'BILLS VETOED BY THE GOVERNOR'}])
            // })
            dispatch(actsActionCreators.receiveActsCategoryList([{ label: 'Vetoes', value: 'BILLS VETOED BY THE GOVERNOR' }, { label: 'Legislation Stats', value: 'SUMMARY OF THE LEGISLATION STATISTICS' }]));
        }
    },

    // GET ACTS CATEGORY LIST FOR ACTS MGMT GRID
    requestActsChapterNumbersList: () => ({
        type: actsChapterNumbersListRequest,
        actsChapterNumbersList: [],
        isFetching: true
    }),

    receiveActsChapterNumbersList: (list, message) => ({
        type: actsChapterNumbersListSuccess,
        isFetching: false,
        actsChapterNumbersList: list,
        actsChapterNumbersMessage: message
    }),

    actsChapterNumbersListError: (message) => ({
        type: actsChapterNumbersListFailure,
        isFetching: false,
        actsChapterNumbersList: [],
        actsChapterNumbersMessage: message
    }),

    getChapterNumbers: (params) => {
        return dispatch => {
            dispatch(actsActionCreators.requestActsChapterNumbersList());

            const headers = {
                "Content-Type": "application/json; charset=utf-8",
            }
            return partnerRequest(API_URL + '/ReportFileGeneration/api/GetChapterNumbersBySession?sessionID=' + params, 'GET', headers, null, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(actsActionCreators.receiveActsChapterNumbersList([]));
                        return Promise.reject(result.message)
                    } else if (result[0] === REQUEST_SUCCESS) {
                        // Dispatch the success action
                        dispatch(actsActionCreators.receiveActsChapterNumbersList(result[1].map(x => { return { label: x, value: x } })))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(actsActionCreators.receiveActsChapterNumbersList([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    // GENERATE ACTS APPENDIX
    receiveActsAppendix: (obj) => ({
        type: actsAppendixBuildSuccess,
        actsAppendixBuildError: '',
        actsAppendixBuild: obj
    }),

    receiveActsAppendixError: (message) => ({
        type: actsAppendixBuildFailure,
        actsAppendixBuildError: message
    }),

    generateActsAppendix: (params) => {

        return dispatch => {
            let config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                }
            }

            let url = API_URL + '/ReportFileGeneration/api/GenerateActsCategoryType?sessionID=' + params.sessionID + '&categoryType=' + params.categoryType;

            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(journalActionCreators.receiveActsAppendixError("File not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(actsActionCreators.receiveActsChaptersError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        let reader = result.body.getReader();
                        let { value: chunk, done: readerDone } = await reader.read();
                        let decoder = new TextDecoder;
                        chunk = chunk ? decoder.decode(chunk, { stream: true }) : "";
                        while (!readerDone) {
                            let result = await reader.read();
                            readerDone = result.done;
                            chunk += result.value ? decoder.decode(result.value, { stream: true }) : "";
                        }
                        dispatch(actsActionCreators.receiveActsChapters(chunk));
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GENERATE ACTS CHAPTERS
    receiveActsChapters: (objResp) => ({
        type: actsChaptersBuildSuccess,
        actsChaptersData: objResp,
        actsChaptersBuildError: '',
    }),

    receiveActsChaptersError: (message) => ({
        type: actsChaptersBuildFailure,
        actsChaptersBuildError: message
    }),

    generateActsChapters: (params) => {

        return dispatch => {
            let config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                }
            }

            let url = API_URL + '/ReportFileGeneration/api/GenerateActs?sessionID=' + params;

            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(journalActionCreators.receiveActsChaptersError("File not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(actsActionCreators.receiveActsChaptersError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        let reader = result.body.getReader();
                        let { value: chunk, done: readerDone } = await reader.read();
                        let decoder = new TextDecoder;
                        chunk = chunk ? decoder.decode(chunk, { stream: true }) : "";
                        while (!readerDone) {
                            let result = await reader.read();
                            readerDone = result.done;
                            chunk += result.value ? decoder.decode(result.value, { stream: true }) : "";
                        }
                        dispatch(actsActionCreators.receiveActsChapters(chunk));

                        // Limited size per browser???
                        // await result.body.getReader().read().then(({ value, done }) => {
                        //     console.log(value.length, done)
                        //     let decoder = new TextDecoder;
                        //     dispatch(actsActionCreators.receiveActsChapters(decoder.decode(value)));
                        // })
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GENERATE ACTS CHAPTERS RANGE
    receiveActsChaptersRange: (objResp) => ({
        type: actsChaptersRangeBuildSuccess,
        actsChaptersRangeData: objResp,
        actsChaptersRangeBuildError: '',
    }),

    receiveActsChaptersRangeError: (message) => ({
        type: actsChaptersRangeBuildFailure,
        actsChaptersRangeBuildError: message
    }),

    generateActsChaptersRange: (params) => {

        return dispatch => {
            let config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                }
            }

            let url = API_URL + '/ReportFileGeneration/api/GenerateActsByChapterRange?sessionID=' + params;

            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(journalActionCreators.categoryDataError("File not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(actsActionCreators.receiveActsChaptersRangeError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        let reader = result.body.getReader();
                        let { value: chunk, done: readerDone } = await reader.read();
                        let decoder = new TextDecoder;
                        chunk = chunk ? decoder.decode(chunk, { stream: true }) : "";
                        while (!readerDone) {
                            let result = await reader.read();
                            readerDone = result.done;
                            chunk += result.value ? decoder.decode(result.value, { stream: true }) : "";
                        }
                        dispatch(actsActionCreators.receiveActsChaptersRange(chunk));
                    }
                }).catch(err => Promise.reject(err));
        }
    },

    // GENERATE ACTS Resolutions
    receiveActsResolutions: (objResp) => ({
        type: actsResolutionsBuildSuccess,
        actsResolutionsData: objResp,
        actsResolutionsBuildError: '',
    }),

    receiveActsResolutionsError: (message) => ({
        type: actsResolutionsBuildFailure,
        actsResolutionsBuildError: message
    }),

    generateActsResolutions: (params) => {

        return dispatch => {
            let config = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "authorization": "Bearer " + localStorage.getItem('LIS_ID_TOKEN'),
                    "WebAPIKey": process.env.REACT_APP_API_KEY
                }
            }

            let url = API_URL + '/ReportFileGeneration/api/GenerateResolutionsActs?sessionID=' + params;

            return fetch(url, config)
                .then(async result => {
                    if (!result) {
                        dispatch(journalActionCreators.receiveActsResolutionsError("File not generated"));
                    } else if (result.status === 400) {
                        await result.body.getReader().read().then(({ value }) => {
                            const error = JSON.parse(Buffer.from(value.buffer).toString())
                            dispatch(actsActionCreators.receiveActsResolutionsError(error.Name || "Unknown Error"));
                        })
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    } else {
                        let reader = result.body.getReader();
                        let { value: chunk, done: readerDone } = await reader.read();
                        let decoder = new TextDecoder;
                        chunk = chunk ? decoder.decode(chunk, { stream: true }) : "";
                        while (!readerDone) {
                            let result = await reader.read();
                            readerDone = result.done;
                            chunk += result.value ? decoder.decode(result.value, { stream: true }) : "";
                        }
                        dispatch(actsActionCreators.receiveActsResolutions(chunk));
                    }
                }).catch(err => Promise.reject(err));
        }
    }
}

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {

        case actsCategoryListRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case actsCategoryListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                actsCategoryList: action.actsCategoryList,
                actsMessage: ''
            })
        case actsCategoryListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                actsList: action.actsCategoryList,
                actsMessage: action.actsMessage
            })
        case actsChapterNumbersListRequest:
            return Object.assign({}, state, {
                isFetching: true
            })
        case actsChapterNumbersListSuccess:
            return Object.assign({}, state, {
                isFetching: false,
                actsChapterNumbersList: action.actsChapterNumbersList,
                actsChapterNumbersMessage: ''
            })
        case actsChapterNumbersListFailure:
            return Object.assign({}, state, {
                isFetching: false,
                actsChapterNumbersList: action.actsChapterNumbersList,
                actsChapterNumbersMessage: action.actsChapterNumbersMessage
            })
        case actsAppendixBuildSuccess:
            return Object.assign({}, state, {
                actsAppendixBuildError: '',
                actsAppendixBuild: action.actsAppendixBuild
            })
        case actsAppendixBuildFailure:
            return Object.assign({}, state, {
                actsAppendixBuildError: action.actsAppendixBuildError
            })
        case actsChaptersBuildSuccess:
            return Object.assign({}, state, {
                actsChaptersBuildError: '',
                actsChaptersData: action.actsChaptersData
            })
        case actsChaptersBuildFailure:
            return Object.assign({}, state, {
                actsChaptersBuildError: action.actsChaptersBuildError
            })
        case actsChaptersRangeBuildSuccess:
            return Object.assign({}, state, {
                actsChaptersRangeBuildError: '',
                actsChaptersRangeData: action.actsChaptersRangeData
            })
        case actsChaptersRangeBuildFailure:
            return Object.assign({}, state, {
                actsChaptersRangeBuildError: action.actsChaptersRangeBuildError
            })
        case actsResolutionsBuildSuccess:
            return Object.assign({}, state, {
                actsResolutionsBuildError: '',
                actsResolutionsData: action.actsResolutionsData
            })
        case actsResolutionsBuildFailure:
            return Object.assign({}, state, {
                actsResolutionsBuildError: action.actsResolutionsBuildError
            })
        default:
            return state
    }
};
