import React from 'react';
import { Route } from 'react-router';
import ActsManagementGrid from './lis-acts-grid';

class ActsManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedSession: '',
            selectedCategory: ''
        };
        this.handleCategoryChange = this.handleCategoryChange.bind(this);
        this.handleSessionChange = this.handleSessionChange.bind(this);
    }

    handleCategoryChange(value) {
        this.setState({
            selectedCategory: value
        })
    }

    handleSessionChange(value, callback) {
        this.setState({
            selectedSession: value
        },
            () => callback && callback()
        );
    }

    render() {
        return (
            <div>
                <Route exact path={this.props.match.path} render={props => <ActsManagementGrid
                    {...props}
                    selectedCategory={this.state.selectedCategory}
                    selectedSession={this.state.selectedSession}
                    handleSessionChange={this.handleSessionChange}
                    handleCategoryChange={this.handleCategoryChange}
                />} />
            </div>
        )
    }
}

export default ActsManagement;
