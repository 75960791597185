import React from 'react';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { committeeActionCreators } from '../../../stores/lis-committee-store';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { navActionCreators } from '../../../stores/lis-nav-store';

import LoaderComponent from '../../../lis-shared/lis-layout/components/lis-loader-component';
import { cancelRequest } from '../../../services/request.service';
import { memberActionCreators } from '../../../stores/lis-members-store';

class CommitteeListComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            activeKey: '',
            listMembers: [],
            houseCommitteesIsLoaded: false,
            senateCommitteesIsLoaded: false,
            sessionData: '',
            houseCommittees: [],
            senateCommittees: [],
            conveneDate: moment().format(),
            showSenateChamber: true,
            showHouseChamber: true
        };
        this.getCommitteeLists = this.getCommitteeLists.bind(this);
        this.toggleChamber = this.toggleChamber.bind(this);
        this.toggleGetMembers = this.toggleGetMembers.bind(this);
        this.setupPage = this.setupPage.bind(this);
    }

    getCommitteeLists(sessionID, chamberCode, listName) {
        var params = 'sessionID=' + sessionID + '&' + 'chamberCode=' + chamberCode

        this.props.actions.getCommitteeList(params).then(() =>
            this.setState({
                [listName]: this.props.committee.committeeList,
                [listName + 'IsLoaded']: true
            })
        )
    }

    toggleChamber(chamber) {
        this.setState(state => ({
            [chamber]: !state[chamber]
        }));
    }

    toggleGetMembers(committeeId) {
        const sessionData = this.state.sessionData;
        var params = 'sessionCode=' + sessionData.SessionCode + '&CommitteeID=' + committeeId;
        if (this.state[committeeId]) {
            this.setState(prevState => ({
                [committeeId + 'isActive']: !prevState[committeeId + 'isActive']
            }));
            return;
        }
        this.props.actions.getMembersByCommittee(params).then(() => {
            // The Chairman needs to be the at the top of the list
            const chairmanIndex = this.props.members.membersByCommittee.findIndex(member => member.CommitteeRoleTitle === 'Chairman');
            let membersByCommittee = [...this.props.members.membersByCommittee];
            if (chairmanIndex !== -1) {
                let chairman = membersByCommittee[chairmanIndex];
                membersByCommittee.splice(chairmanIndex, 1);
                membersByCommittee.unshift(chairman);
            }
            const sessionStartObj = sessionData.SessionEvents && sessionData.SessionEvents.length && sessionData.SessionEvents.find(date => date.DisplayName === "Session Start");
            const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate) : '';
            const adjournmentEvent = sessionData ? sessionData.SessionEvents.find(event => event.DisplayName === 'Adjournment') : '';
            const endDate = adjournmentEvent ? moment(adjournmentEvent.ActualDate) : moment();
            const memberString = membersByCommittee.map((member, memberIndex) => {
                let detailString = '';
                if (member.CommitteeRoleTitle === "Chairman") {
                    detailString += '(' + member.CommitteeRoleTitle;
                }
                if (member.EffectiveBeginDate && moment(member.EffectiveBeginDate).isAfter(startDate)) {
                    detailString = detailString ? detailString + ', ' : '(';
                    detailString += 'Arrived: ' + moment(member.EffectiveBeginDate).format('MM/DD/YYYY');
                }
                if (member.EffectiveEndDate && moment(member.EffectiveEndDate).isBefore(endDate)) {
                    detailString = detailString ? detailString + ', ' : '(';
                    detailString += 'Left: ' + moment(member.EffectiveEndDate).format('MM/DD/YYYY');
                }
                if (detailString) {
                    detailString = ' ' + detailString + ')';
                };
                return (<React.Fragment key={memberIndex}>
                    <Link style={{ display: 'inline', margin: 0 }} to={`/session-details/${sessionData.SessionCode}/member-information/${member.MemberNumber}/member-details`}>{member.PatronDisplayName}</Link>
                    {detailString}
                    {memberIndex !== membersByCommittee.length - 1 && ", "}
                </React.Fragment>)
            })
            this.setState(prevState => ({
                [committeeId]: memberString,
                [committeeId + 'isActive']: !prevState[committeeId + 'isActive']
            }));
        });
    }

    //When component has mounted, make the call to list the committees
    componentDidMount() {
        if (parseInt(this.props.match.params.sessioncode, 10) > 0) {
            //Update the session selector component, which will run componentDidUpdate and call setupPage on its own
            const sessionCode = this.props.match.params.sessioncode
            if (this.props.nav.session !== sessionCode) {
                this.props.actions.changeSession(sessionCode)
            } else {
                this.setupPage(sessionCode);
            }
        }
    }

    componentDidUpdate(prevProps) {
        // Setup the page if the global selected session changes. Change the URL to reflect the session change
        if (prevProps.nav.session !== this.props.nav.session) {
            this.props.history.push('/session-details/' + this.props.nav.session + '/committee-information');
            this.setupPage(this.props.nav.session);
        } else if (this.props.nav.session !== this.props.match.params.sessioncode) {
            // If the url changes then update the global selected session with whatever the url is
            const sessionCode = this.props.match.params.sessioncode;
            if (!isNaN(sessionCode)) {
                this.props.actions.changeSession(sessionCode);
            }
        }
    }

    componentWillUnmount() {
        cancelRequest();
    }

    setupPage(sessionCode) {
        this.props.actions.getSessionByCode(sessionCode).then(() => {
            const sessionStartObj = this.props.session.selectedSession.SessionEvents && this.props.session.selectedSession.SessionEvents.length && this.props.session.selectedSession.SessionEvents.find(date => date.DisplayName === "Session Start");
            const startDate = sessionStartObj ? moment(sessionStartObj.ActualDate) : '';
            this.setState({
                sessionData: this.props.session.selectedSession,
                conveneDate: startDate
            }, () => {
                this.getCommitteeLists(this.state.sessionData.SessionID, 'H', 'houseCommittees')
                this.getCommitteeLists(this.state.sessionData.SessionID, 'S', 'senateCommittees')
            })
        });
    }

    render() {
        const { sessionData, houseCommittees, senateCommittees } = this.state;
        const sessionHeader = sessionData ? sessionData.SessionYear + ' ' + sessionData.DisplayName : '';
        const listCommittee = (committee, i) => {
            const legislationObj = {
                selectedSession: sessionData.SessionID,
                selectedBillStatus: 2,
                selectedCommittee: committee.CommitteeID,
            };
            const legislationLink = window.btoa(JSON.stringify(legislationObj));
            return (<div key={i} className={this.state[committee.CommitteeID + 'isActive'] ? 'parent-li committee-li slidedown-open' : 'parent-li committee-li slidedown-closed'}>
                <div className="toggle-row committee-row">
                    <Link to={`/session-details/${sessionData.SessionCode}/committee-information/${committee.CommitteeNumber}/committee-details`}>{committee.Name}</Link>
                    <span />
                    <Link to={`/bill-search?q=${legislationLink}`}>Legislation</Link>
                    <span />
                    <label>Members</label>
                    <div className="button-container">
                        <button aria-expanded={!!this.state[committee.CommitteeID + 'isActive']} className="arrow-down" onClick={() => this.toggleGetMembers(committee.CommitteeID, sessionData.SessionCode)}>Show members</button>
                    </div>
                </div>
                {this.state[committee.CommitteeID + 'isActive'] &&
                    <div className="list-desc">
                        Members: {this.state[committee.CommitteeID]}
                    </div>
                }
            </div>)
        }
        const listHouseCommittees = houseCommittees.map(listCommittee);
        const listSenateCommittees = senateCommittees.map(listCommittee);

        return (
            <div className="public-chamber-data">
                <div>
                    <h2>{sessionHeader}</h2>
                </div>
                <div className="public-list inner-grid two">
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showHouseChamber")} aria-expanded={this.state.showHouseChamber} className={`chamber-name ${this.state.showHouseChamber ? "opened" : ""}`}>House Committees</h3>
                        <LoaderComponent data={this.state.houseCommitteesIsLoaded} />
                        <ul className="slidedown-list">
                            {this.state.showHouseChamber &&
                                <>
                                    {listHouseCommittees}
                                </>
                            }
                        </ul>
                    </div>
                    <div>
                        <h3 role="button" onClick={() => this.toggleChamber("showSenateChamber")} aria-expanded={this.state.showSenateChamber} className={`chamber-name ${this.state.showHouseChamber ? "opened" : ""}`}>Senate Committees</h3>
                        <LoaderComponent data={this.state.senateCommitteesIsLoaded} />
                        <ul className="slidedown-list">
                            {this.state.showSenateChamber &&
                                <>
                                    {listSenateCommittees}
                                </>
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}

const PublicCommitteeList = connect(
    (state) => {
        const { committee, members, session, nav } = state;
        return {
            committee,
            members,
            session,
            nav
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, committeeActionCreators, memberActionCreators, sessionActionCreators, navActionCreators), dispatch)
        }
    }
)(CommitteeListComponent)

export default PublicCommitteeList;
