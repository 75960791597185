import React from "react";
import '../../../stylesheets/shared/react-table.css';
import moment from 'moment-timezone';
import * as Fields from '../../../lis-shared/lis-layout/components/lis-forms-component';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { sessionActionCreators } from '../../../stores/lis-session-store';
import { cancelRequest } from '../../../services/request.service';
import { navActionCreators } from "../../../stores/lis-nav-store";
import { actsActionCreators } from "../../../stores/lis-acts-store";

class ActsGridComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            actsDate: moment(),
            actsList: [],
            isLoaded: false,
            selected: {},
            sessionList: [],
            sessionDropdown: [],
            selectedSessionToolbar: '',
            selectedSessionGrid: '',
            filtered: [{ id: 'all', value: 'true' }],
            filterAll: 'true',
            chapterStart: [{ id: '', value: '' }],
            chapterEnd: [{ id: '', value: '' }],
        };
        this.handleDateChange = this.handleDateChange.bind(this);
        this.handleGenerateChapters = this.handleGenerateChapters.bind(this);
        this.handleGenerateChaptersRange = this.handleGenerateChaptersRange.bind(this);
        this.handleGenerateAppendix = this.handleGenerateAppendix.bind(this);
        this.handleGenerateResolutions = this.handleGenerateResolutions.bind(this);
        this.handleChapterStartChange = this.handleChapterStartChange.bind(this);
        this.handleChapterEndChange = this.handleChapterEndChange.bind(this);
    };

    //When component has mounted, make the call to the list users API
    componentDidMount() {
        let selectedSession = this.props.selectedSession;
        let defaultSession = '';
        this.props.actions.getSessionList().then(() => {
            let sessionList = [...this.props.session.sessionList].reverse();
            sessionList.forEach(session => {
                session.label = session.SessionYear + ' ' + session.DisplayName;
                session.value = session.SessionID;
                if (session.IsDefault) {
                    selectedSession = selectedSession || session;
                    defaultSession = session;
                }
            });
            this.setState({
                sessionDropdown: sessionList,
                sessionList: sessionList,
                selectedSessionGrid: defaultSession
            }, () => {
                this.props.actions.getChapterNumbers(this.state.selectedSessionGrid.SessionID);
            });
            this.props.handleSessionChange(selectedSession);
        });

        this.props.actions.getActsCategories();
    }

    componentWillUnmount() {
        cancelRequest();
    }

    handleDateChange(date) {
        this.setState({
            actsDate: moment(date)
        });
    }

    handleSessionSelectChange = value => {
        this.props.handleSessionChange(value, () => this.props.actions.getChapterNumbers(value.SessionID));
    }

    handleCategoryChange = value => {
        this.props.handleCategoryChange(value);
    }

    handleChapterStartChange = value => {
        this.setState({
            chapterStart: value ?? { id: '', value: '' }
        })
    }

    handleChapterEndChange = value => {
        this.setState({
            chapterEnd: value ?? { id: '', value: '' }
        })
    }

    handleGenerateAppendix() {
        let params = { sessionID: this.props.selectedSession.SessionID, categoryType: this.props.selectedCategory.value }
        this.props.actions.generateActsAppendix(params)
            .then(() => {
                if (this.props.acts.actsChaptersBuildError.length === 0 && this.props.acts.actsChaptersData) {
                    this.props.actions.makeToast([{ message: "Appendix Generated", type: "success" }]);
                    let downloadLink = document.createElement('a');
                    let url = window.URL.createObjectURL(new Blob([this.props.acts.actsChaptersData]))
                    downloadLink.setAttribute('href', url);
                    downloadLink.setAttribute('download', this.props.selectedSession.label + " " + this.props.selectedCategory.label + " - Appendix.mml");

                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    document.body.removeChild(downloadLink);
                } else {
                    this.props.actions.makeToast([{ message: "Failed to Generate Appendix", type: "failure" }]);
                }
            })
    }

    handleGenerateChapters() {
        let params = this.props.selectedSession.SessionID;
        this.props.actions.generateActsChapters(params)
            .then(() => {
                if (this.props.acts.actsChaptersBuildError.length === 0 && this.props.acts.actsChaptersData) {
                    this.props.actions.makeToast([{ message: "Chapters Generated", type: "success" }]);
                    let downloadLink = document.createElement('a');
                    let url = window.URL.createObjectURL(new Blob([this.props.acts.actsChaptersData]))
                    downloadLink.setAttribute('href', url);
                    downloadLink.setAttribute('download', this.props.selectedSession.label + " - Chapters.mml");

                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    document.body.removeChild(downloadLink);
                } else {
                    this.props.actions.makeToast([{ message: "Failed to Generate Chapters", type: "failure" }]);
                }
            })
    }

    handleGenerateChaptersRange() {
        let params = this.props.selectedSession.SessionID;
        if (this.state.chapterStart.label !== '') {
            params += `&chapterStart=${this.state.chapterStart.value}`;
        }
        if (this.state.chapterEnd.label !== '') {
            params += `&chapterEnd=${this.state.chapterEnd.value}`;
        }
        this.props.actions.generateActsChaptersRange(params)
            .then(() => {
                if (this.props.acts.actsChaptersRangeBuildError.length === 0 && this.props.acts.actsChaptersRangeData) {
                    this.props.actions.makeToast([{ message: "Chapters Generated", type: "success" }]);
                    let downloadLink = document.createElement('a');
                    let url = window.URL.createObjectURL(new Blob([this.props.acts.actsChaptersRangeData]))
                    downloadLink.setAttribute('href', url);
                    downloadLink.setAttribute('download', this.props.selectedSession.label + " - Chapters.mml");

                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    document.body.removeChild(downloadLink);
                } else {
                    this.props.actions.makeToast([{ message: "Failed to Generate Chapters", type: "failure" }]);
                }
            })
    }

    handleGenerateResolutions() {
        let params = this.props.selectedSession.SessionID;
        this.props.actions.generateActsResolutions(params)
            .then(() => {
                if (this.props.acts.actsResolutionsBuildError.length === 0 && this.props.acts.actsResolutionsData) {
                    this.props.actions.makeToast([{ message: "Resolutions Generated", type: "success" }]);
                    let downloadLink = document.createElement('a');
                    let url = window.URL.createObjectURL(new Blob([this.props.acts.actsResolutionsData]))
                    downloadLink.setAttribute('href', url);
                    downloadLink.setAttribute('download', this.props.selectedSession.label + " - Resolutions.mml");

                    downloadLink.style.display = 'none';
                    document.body.appendChild(downloadLink);
                    downloadLink.click();

                    document.body.removeChild(downloadLink);
                } else {
                    this.props.actions.makeToast([{ message: "Failed to Generate Resolutions", type: "failure" }]);
                }
            })
    }

    render() {
        return (
            <div>
                <div className="header-half">
                    <h1>Acts of Assembly Management</h1>
                </div>
                <div className="toolbar acts-toolbar dlas-forms">
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedSession}
                            options={this.state.sessionDropdown}
                            id="sessionDropdown"
                            onChange={this.handleSessionSelectChange}
                        />
                    </div>
                    <div>
                        <Fields.Dropdown
                            value={this.props.selectedCategory}
                            options={this.props.acts.actsCategoryList}
                            id="category-dropdown"
                            onChange={this.handleCategoryChange}
                        />
                    </div>
                    <button className="button primary" onClick={() => this.handleGenerateAppendix()}>Generate Appendix</button>
                    <div className="chapter-ranges">
                        <Fields.Dropdown
                            value={this.state.selectedChapterStart}
                            options={this.props.acts.actsChapterNumbersList}
                            id="chapter-start-dropdown"
                            onChange={this.handleChapterStartChange}
                            placeholder="Start"
                            isClearable
                        />
                        <Fields.Dropdown
                            value={this.state.selectedChapterEnd}
                            options={this.props.acts.actsChapterNumbersList}
                            id="chapter-end-dropdown"
                            onChange={this.handleChapterEndChange}
                            placeholder="End"
                            isClearable
                        />
                    </div>
                    <button className="button primary" onClick={() => this.handleGenerateChaptersRange()}>Generate Chapters</button>
                    <button className="button primary less-horizontal-padding" onClick={() => this.handleGenerateResolutions()}>Generate Resolutions</button>
                </div>
                {this.state.message &&
                    <span className="fail small-text">{this.state.message}</span>
                }
            </div>
        );
    }
}

const ActsManagementGrid = connect(
    (state) => {
        const { acts, session } = state;
        return {
            acts,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, actsActionCreators, navActionCreators), dispatch)
        }
    }
)(ActsGridComponent)


export default ActsManagementGrid;
