import { partnerRequest, publicRequest } from '../services/request.service';

const API_URL = window.env ? window.env.API_SERVER : '';
const NO_CONTENT = 2;
const REQUEST_FAILURE = 1;
const REQUEST_SUCCESS = 0;

const reportTypesSuccess = "REPORT_TYPES_SUCCESS";
const reportTypesFailure = "REPORT_TYPES_FAILURE";

const reportTemplatesSuccess = "REPORT_TEMPLATES_SUCCESS";
const reportTemplatesFailure = "REPORT_TEMPLATES_FAILURE";

const reportSaveTemplateSuccess = "REPORT_SAVE_TEMPLATE_SUCCESS";
const reportSaveTemplateFailure = "REPORT_SAVE_TEMPLATE_FAILURE";

const reportDeleteTemplateSuccess = "REPORT_DELETE_TEMPLATE_SUCCESS";
const reportDeleteTemplateFailure = "REPORT_DELETE_TEMPLATE_FAILURE";

const reportSuccess = "GET_REPORT_SUCCESS";
const reportFailure = "GET_REPORT_FAILURE";

const billReportInformationSuccess = "BILL_REPORT_INFORMATION_SUCCESS";
const billReportInformationFailure = "BILL_REPORT_INFORMATION_FAILURE";

const shareReportRequest = "SHARE_REPORT_REQUEST";

const initialState = {
    reportTypes: [],
    reportTypesError: '',
    report: [],
    reportError: '',
    shareReport: '',
    billReportInformation: [],
    billReportInformationError: '',
    reportTemplates: [],
    reportTemplatesError: ''
};

export const reportActionCreators = {

    receiveReportTypes: res => ({
        type: reportTypesSuccess,
        reportTypes: res,
    }),

    reportTypesError: message => ({
        type: reportTypesFailure,
        reportTypesError: message,
    }),

    getReportTypes: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/GetPartnerReportListAsync', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.reportTypesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveReportTypes(result[1].Reports))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.receiveReportTypes([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveReportTemplates: res => ({
        type: reportTemplatesSuccess,
        reportTemplates: res,
    }),

    reportTemplatesError: message => ({
        type: reportTemplatesFailure,
        reportTemplatesError: message,
    }),

    getReportTemplates: () => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/GetReportParameters', 'GET', headers, '', '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.reportTemplatesError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveReportTemplates(result[1].ListItems))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.receiveReportTemplates([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveSaveReportTemplate: res => ({
        type: reportSaveTemplateSuccess,
    }),

    reportSaveTemplateError: message => ({
        type: reportSaveTemplateFailure,
        reportSaveTemplateError: message,
    }),

    saveReportTemplate: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/SaveReportParameters', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.reportSaveTemplateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveSaveReportTemplate(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.receiveSaveReportTemplate([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveDeleteReportTemplate: res => ({
        type: reportDeleteTemplateSuccess,
    }),

    reportDeleteTemplateError: message => ({
        type: reportDeleteTemplateFailure,
        reportDeleteTemplateError: message,
    }),

    deleteReportTemplate: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/SaveReportParameters', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.reportDeleteTemplateError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveDeleteReportTemplate(result[1]))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.receiveDeleteReportTemplate([]));
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveReport: res => ({
        type: reportSuccess,
        report: res,
    }),

    reportError: message => ({
        type: reportFailure,
        reportError: message,
    }),

    getReport: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/GetPartnerReportDataAsync', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.reportError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveReport(result[1].ReportDataList))
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.reportError('No Data Found'));
                        return Promise.reject('No Data Found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    receiveBillReportInformation: res => ({
        type: billReportInformationSuccess,
        billReportInformation: res,
    }),

    billReportInformationError: message => ({
        type: billReportInformationFailure,
        billReportInformationError: message,
    }),

    getBillReportInformation: (params) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/GetReportInformation', 'GET', headers, '', params)
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.billReportInformationError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveBillReportInformation(result[1].StronglyTypedObject));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.billReportInformationError('No Data Found'));
                        return Promise.reject('No Data Found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    saveBillReportInformation: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return publicRequest((API_URL || process.env.REACT_APP_REPORT_FILE_GEN_SERVICE) + '/ReportFileGeneration/api/SaveReportInformation', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch(reportActionCreators.billReportInformationError(result[1]))
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch(reportActionCreators.receiveBillReportInformation(result[1].StronglyTypedObject));
                    } else if (result[0] === NO_CONTENT) {
                        dispatch(reportActionCreators.billReportInformationError('No Data Found'));
                        return Promise.reject('No Data Found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },

    shareReport: (body) => {
        return dispatch => {
            let headers = {
                'content-type': "application/json; charset=utf-8"
            }
            return partnerRequest((API_URL || process.env.REACT_APP_NOTIFICATIONS_API_URL) + '/Notification/api/ShareReportAsync', 'POST', headers, body, '')
                .then(result => {
                    if (result[0] === REQUEST_FAILURE) {
                        dispatch({
                            type: shareReportRequest,
                            shareReportError: result[1],
                        })
                        return Promise.reject(result[1])
                    } else if (result[0] === REQUEST_SUCCESS) {
                        dispatch({
                            type: shareReportRequest,
                            shareReport: result[1],
                        })
                    } else if (result[0] === NO_CONTENT) {
                        dispatch({
                            type: shareReportRequest,
                            shareReportError: "No data returned",
                        })
                        return Promise.reject('No Data Found');
                    } else if (result.name === 'AbortError') {
                        return Promise.reject("Aborted");
                    }
                }).catch(err => Promise.reject(err))
        }
    },
};

export const reducer = (state, action) => {

    state = state || initialState;

    switch (action.type) {
        case reportTypesSuccess:
            return Object.assign({}, state, {
                reportTypes: action.reportTypes,
                reportTypesError: ''
            })
        case reportTypesFailure:
            return Object.assign({}, state, {
                reportTypesError: action.reportTypesError
            })
        case reportTemplatesSuccess:
            return Object.assign({}, state, {
                reportTemplates: action.reportTemplates,
                reportTemplatesError: ''
            })
        case reportTemplatesFailure:
            return Object.assign({}, state, {
                reportTemplatesError: action.reportTemplatesError
            })
        case reportSaveTemplateSuccess:
            return Object.assign({}, state, {
                reportTemplatesError: ''
            })
        case reportSaveTemplateFailure:
            return Object.assign({}, state, {
                reportSaveTemplateError: action.reportSaveTemplateError
            })
        case reportDeleteTemplateSuccess:
            return Object.assign({}, state, {
                reportTemplatesError: ''
            })
        case reportDeleteTemplateFailure:
            return Object.assign({}, state, {
                reportDeleteTemplateError: action.reportDeleteTemplateError
            })
        case reportSuccess:
            return Object.assign({}, state, {
                report: action.report,
                reportError: ''
            })
        case reportFailure:
            return Object.assign({}, state, {
                reportError: action.reportError
            })
        case billReportInformationSuccess:
            return Object.assign({}, state, {
                billReportInformation: action.billReportInformation,
                billReportInformationError: ''
            })
        case billReportInformationFailure:
            return Object.assign({}, state, {
                billReportInformationError: action.billReportInformationError
            })
        case shareReportRequest:
            return Object.assign({}, state, {
                shareReport: action.shareReport || state.shareReport,
                shareReportError: action.shareReportError || ''
            })
        default:
            return state;
    }
};