import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { collectionActionCreators } from '../../../stores/lis-collection-store';
import { authActionCreators } from '../../../stores/lis-auth-store';
import CreatableSelect from 'react-select/creatable';


class Collections extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            notes: [],
            expanded: false
        };

        this.toggleExpandNotes = this.toggleExpandNotes.bind(this);
    }

    componentDidMount() {
        this.props.actions.getCollections("?SessionID=" + this.props.sessionId)
            .then(() => {
                const legId = this.props.billData.LegislationID;
                let notes = [];
                this.props.collection.collections.forEach(collection => {
                    const legislationInCollection = collection.WatchListLegislation.find(leg => leg.LegislationID === legId);
                    if (legislationInCollection) {
                        notes.push({
                            ...legislationInCollection,
                            Name: collection.Name,
                            Description: collection.Description
                        });
                    }
                });
                this.setState({
                    notes: notes
                });
            }).catch(err => {
                if (err === 'Aborted') {
                    return
                }
            });
    }

    componentDidUpdate(prevProps) {
        if (!this.props.showNoteForm && this.props.showNoteForm !== prevProps.showNoteForm) {
            this.props.actions.getCollections("?SessionID=" + this.props.sessionId)
                .then(() => {
                    const legId = this.props.billData.LegislationID;
                    let notes = [];
                    this.props.collection.collections.forEach(collection => {
                        const legislationInCollection = collection.WatchListLegislation.find(leg => leg.LegislationID === legId);
                        if (legislationInCollection) {
                            notes.push({
                                ...legislationInCollection,
                                Name: collection.Name,
                                Description: collection.Description
                            });
                        }
                    });
                    this.setState({
                        notes: notes
                    });
                })
        }
    }

    toggleExpandNotes() {
        this.setState({
            expanded: !this.state.expanded
        })
    }

    render() {
        return (<div className='dlas-forms bill-details-watchlist-form' style={{ margin: "20px 0" }}>
            <div className="description" style={{ display: 'flex', gap: '5px', alignItems: 'center' }}>
                <h4>Watchlist(s)</h4>
                <a className="button small-button" style={{ marginLeft: "10px", fontSize: "14px", cursor: "pointer" }} onClick={() => this.props.toggleNotesForm()}>{this.props.showNoteForm ? "Close" : "Edit"}</a>
            </div>
            {this.props.showNoteForm &&
                <div className='bill-details-subform'>
                    <p style={{ margin: "0" }}>Create a new watchlist and add this legislation or add/remove this legislation from existing watchlist(s). Legislation notes can be applied to one or across multiple watchlists.</p>
                    <div className="inner-grid bill-details-watchlist-controls" style={{ marginBottom: "10px" }}>
                        <div>
                            <label htmlFor="sr-watchlist-name-control">Name</label>
                            <CreatableSelect
                                id="sr-watchlist-name-control"
                                styles={this.props.selectStyle}
                                options={this.props.collection.collections}
                                getOptionLabel={opt => opt.label || `${opt.Name} ${opt.Description && `- ${opt.Description}`}`}
                                getOptionValue={opt => opt.value || opt.WatchListID}
                                value={this.props.noteWatchlist}
                                onChange={this.props.handleNoteWatchlistChange}
                                placeholder={"Select or Create"}
                                isMulti
                            />
                        </div>
                        <div className="bill-details-watchlist-input" style={{ display: "grid" }}>
                            <label htmlFor='sr-note-text-control' style={{ margin: "4px 0 0 0" }}>Note</label>
                            <input
                                id="sr-note-text-control"
                                value={this.props.noteText}
                                onChange={this.props.handleNoteTextChange}
                            />
                            <div />
                        </div>
                        <button style={{ marginTop: "25px", height: "30px" }} disabled={this.props.saving || !this.props.noteWatchlists || this.props.noteWatchlists.length === 0 || this.props.noteWatchlists.find(x => x.__isNew__)} className="button danger" type="button" onClick={() => this.props.handleRemoveWatchlists()}>Remove</button>
                        <button style={{ marginTop: "25px", height: "30px" }} disabled={this.props.saving || !this.props.noteWatchlists || this.props.noteWatchlists.length === 0} className="button" type="button" onClick={() => this.props.handleSaveWatchlists()}>Save</button>
                    </div>
                </div>
            }
            {this.state.notes && this.state.notes.length > 0 &&
                <>
                    <div className='note-wrapper inner-grid one-and-eight' style={{ marginTop: "5px" }}>
                        <p className='small-text' style={{ color: "#3774ac" }}>Name</p>
                        <p className='small-text' style={{ color: "#3774ac" }}>Note</p>
                    </div>
                    <hr />
                </>
            }
            {this.state.notes && this.state.notes.sort((a, b) => a.Name.localeCompare(b.Name)).map((note, noteIndex) =>
                <>
                    <div key={noteIndex} className='note-wrapper inner-grid one-and-eight'>
                        <span className="small-text">{note.Name}</span>
                        <p className="small-text">{note.Note && note.Note.length < 345 || this.state.expanded ? note.Note : note.Note && note.Note.substring(0, 345) + "..."}</p>
                    </div>
                    <hr />
                </>
            )}
            {this.state.notes && this.state.notes.find(x => x.Note && x.Note.length > 400) && <button className='button small-button note-button' type="button" onClick={() => this.toggleExpandNotes()}>{(this.state.expanded ? "Collapse" : "Expand") + " Notes"}</button>}
        </div>)
    }
}

export default connect(
    (state) => {
        const { collection, login } = state;
        return {
            collection,
            login
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, authActionCreators, collectionActionCreators), dispatch)
        }
    }
)(Collections)