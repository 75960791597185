import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { sessionActionCreators } from '../../stores/lis-session-store';
import { memberActionCreators } from '../../stores/lis-members-store';
import { billActionCreators } from '../../stores/lis-legislation-store';
import CreatableSelect from 'react-select/creatable'
import BillInfoComponent from '../../lis-shared/lis-search/lis-bill-info';
import { collectionActionCreators } from '../../stores/lis-collection-store';
import { navActionCreators } from '../../stores/lis-nav-store';

const selectStyle = {
    control: styles => ({
        ...styles,
        minHeight: '0px',
        padding: '0.12em 0.6em !important',
    }),
    valueContainer: styles => ({
        ...styles,
        padding: 0,
    }),
    input: styles => ({
        ...styles,
        fontSize: '0.8em',
        lineHeight: 'normal',
    }),
    singleValue: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    placeholder: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    option: styles => ({
        ...styles,
        fontSize: '0.8em'
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: '1px'
    }),
}

const BILL_COLLECTION_AUTHOR = "LegislationCollections";

class PublicMemberLegislation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            noResults: false,
            checkedBills: [],
            collections: [],
            results: [],
            memberData: '',
            sessionData: '',
            selectedWatchlists: [],
            loading: true,
            showPopup: false
        };

        this.addToWatchlists = this.addToWatchlists.bind(this);
        this.handleAddToWatchlists = this.handleAddToWatchlists.bind(this);
        this.handleBillCheckbox = this.handleBillCheckbox.bind(this);
        this.handleWatchlistChange = this.handleWatchlistChange.bind(this);
        this.setupPage = this.setupPage.bind(this);
        this.toggleShowData = this.toggleShowData.bind(this);

        this._billResultRefs = [];
    }

    componentDidMount() {
        this.setupPage();
    }

    addToWatchlists(watchlists, callback) {
        let billsToAdd = [];

        this.state.checkedBills.forEach((c, i) => {
            if (c) {
                billsToAdd.push(this.state.results[i])
            }
        });

        let duplicatedBills = [];
        watchlists.forEach(watchlist => {
            billsToAdd.forEach(bill => {
                if (watchlist.__isNew__) {
                    watchlist.Name = watchlist.label;
                    watchlist.Description = `Created on ${moment().format('MM/DD/YYYY')}`;
                    watchlist.WatchListLegislation = [];
                    delete watchlist.label;
                    delete watchlist.__isNew__;
                    delete watchlist.value;
                }
                // Make sure the bill isn't already a part of the watchlist
                if (!watchlist.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID) || watchlist.WatchListLegislation.find(leg => leg.LegislationID === bill.LegislationID).DeletionDate) {
                    watchlist.WatchListLegislation.push({
                        LegislationID: bill.LegislationID,
                        LegislationNumber: bill.LegislationNumber,
                        Sessions: [{ SessionID: bill.SessionID }],
                        WatchListID: watchlist.WatchListID,
                        Note: bill.Note
                    });
                } else {
                    duplicatedBills.push({ "Watchlist": watchlist.Name, "LegislationNumber": bill.LegislationNumber });
                }
            });
        })

        this.setState({
            collectionIsSaving: true
        }, () => {
            let collections = [...this.state.collections];
            this.props.actions.saveCollections({ WatchLists: watchlists })
                .then(() => {
                    let watchlistSave = [...this.props.collection.collectionSave];
                    let savedCollectionIndex;
                    watchlistSave.forEach(res => {
                        if (!collections.find(coll => coll.WatchListID === res.WatchListID)) {
                            collections.unshift(res);
                            savedCollectionIndex = 0;
                        } else {
                            savedCollectionIndex = collections.findIndex(coll => coll.WatchListID === res.WatchListID);
                            collections[savedCollectionIndex] = res;
                        }
                        res.WatchListLegislation = res.WatchListLegislation || [];
                        res.NotificationCriterias = res.NotificationCriterias || [];
                    })

                    this.setState({
                        collectionIsSaving: false,
                        collections: collections,
                        selectedCollection: watchlists.length === 1 ? collections[savedCollectionIndex] : this.state.selectedCollection,
                        selectedCollectionIndex: watchlists.length === 1 ? savedCollectionIndex : this.state.selectedCollectionIndex,
                        checkedBills: []
                    });
                    if (duplicatedBills.length) {
                        this.props.actions.makeToast([{ message: (new Set(duplicatedBills.map(b => b.LegislationNumber)).size === 1 ? (duplicatedBills[0].LegislationNumber + " is ") : "Multiple added bills were ") + "already in " + (watchlists.length === 1 ? "this watchlist. " : "one or more of these watchlists. ") + (watchlists.length === 1 ? ((billsToAdd.length - duplicatedBills.length) + " bills were added to your watchlist " + watchlists[0].Name) : "All other bills were added."), type: "warning", long: true }]);
                    } else {
                        this.props.actions.makeToast([{ message: (billsToAdd.length === 1 ? billsToAdd[0].LegislationNumber + " was " : billsToAdd.length + " bills were ") + "added to your watchlist" + (watchlists.length > 1 ? "s" : (" " + watchlists[0].Name)), type: "success" }]);
                    }
                    if (callback) {
                        callback(collections)
                    }
                }).catch(err => {
                    if (err === 'Aborted') {
                        return;
                    }
                    console.log(err)
                    this.setState({
                        collectionIsSaving: false
                    });
                    let message;
                    if (err && JSON.parse(err)) {
                        const errorMsg = JSON.parse(err).Name;
                        message = errorMsg && errorMsg.includes('Public users are') ? errorMsg : 'Save Failed'
                    } else {
                        message = "Save Failed";
                    }
                    this.props.actions.makeToast([{ message: message, type: "failure", long: message.includes('Public users are') }]);
                })
        });
    }

    handleAddToWatchlists() {
        this.addToWatchlists(this.state.selectedWatchlists, (saveResultCollections) => {
            let showPopup = { ...this.state.showPopup };
            let watchlists = [...this.state.selectedWatchlists];

            for (let i = 0; i < watchlists.length; i++) {
                const associatedWatchlist = saveResultCollections.find(c => c.WatchListID === watchlists[i].WatchListID);
                if (associatedWatchlist && associatedWatchlist.ModificationDate !== watchlists[i].ModificationDate) {
                    watchlists[i] = associatedWatchlist
                };
            }

            this.setState({ selectedWatchlists: watchlists, showPopup })
        });
    }

    handleBillCheckbox(billIndex, e) {
        let checkedBills = [...this.state.checkedBills];
        let lastCheckedBill = this.state.lastCheckedBill;
        if (e.nativeEvent.shiftKey && this.state.lastCheckedBill !== -1) {
            checkedBills = checkedBills.fill(false);
            for (let i = 0; i < Math.abs(billIndex - this.state.lastCheckedBill); i++) {
                const index = this.state.lastCheckedBill + i * (billIndex - this.state.lastCheckedBill) / Math.abs(billIndex - this.state.lastCheckedBill);
                checkedBills[index] = true;
            }
        } else {
            lastCheckedBill = !checkedBills[billIndex] ? billIndex : this.state.lastCheckedBill;
        }
        checkedBills[billIndex] = !checkedBills[billIndex]
        this.setState({
            checkedBills: checkedBills,
            lastCheckedBill: lastCheckedBill
        });
    }

    handleWatchlistChange(val) {
        this.setState({
            selectedWatchlists: val ? val : []
        });
    }

    setupPage() {
        let getSessions, getMembers;

        if (this.props.session.sessionList.length === 0) {
            getSessions = this.props.actions.getSessionList()
                .then(() => {
                    return Promise.resolve();
                })
        }

        if (this.props.members.memberList.length === 0) {
            getMembers = this.props.actions.getMemberList('sessionCode=' + this.props.match.params.sessioncode)
                .then(() => {
                    return Promise.resolve();
                });
        }

        Promise.all([
            getSessions,
            getMembers
        ]).then(() => {
            let params = {
                MemberID: this.props.members.memberList.find(x => x.MemberNumber === this.props.match.params.membernumber).MemberID,
                PatronTypes: [1],
                SessionID: this.props.session.sessionList.find(x => x.SessionCode === this.props.match.params.sessioncode).SessionID
            }

            this.props.actions.getLegislationIdsList(params).then(() => {
                let legislationIdsResponse = { ... this.props.bills.legislationIdsList };
                legislationIdsResponse.LegislationIds = legislationIdsResponse.LegislationIds.filter((item, pos) => legislationIdsResponse.LegislationIds.findIndex(i => i.LegislationNumber === item.LegislationNumber && i.SessionID === item.SessionID) === pos);

                if (legislationIdsResponse.LegislationIds) {
                    this.props.actions.getBillListByIds({ "LegislationIds": legislationIdsResponse.LegislationIds })
                        .then(() => {
                            this.setState({
                                results: this.props.bills.billListByIds,
                                memberData: this.props.members.memberList.find(x => x.MemberNumber === this.props.match.params.membernumber),
                                sessionData: this.props.session.sessionList.find(x => x.SessionCode === this.props.match.params.sessioncode),
                                loading: false
                            }, () => {
                                if (this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR)) {
                                    this.props.actions.getCollections("?SessionID=" + this.state.sessionData.SessionID)
                                        .then(() => {
                                            this.setState({
                                                collections: this.props.collection.collections
                                            })
                                        })
                                }
                            })
                        });
                } else {
                    this.setState({
                        noResults: true
                    })
                }
            })
        })
    }

    toggleShowData(billID, bool) {
        if (bool === undefined) {
            this.setState(prevState => ({
                [billID]: !prevState[billID]
            }));
        } else {
            //not just toggling, we're explicitly setting (e.g. if searching by keyword, and a bill has been expanded, if the keyword search location changes to bill text,
            //don't just toggle all (which would reset that bill to false), set them all to true)
            this.setState({
                [billID]: bool
            })
        }
    }

    render() {
        return (
            <>
                {this.state.loading &&
                    <div className="spinner"></div>
                }
                {this.state.memberData !== "" && this.state.sessionData !== "" &&
                    <div className="generic-details-page public-details inner-grid">
                        <div>
                            <h2 style={{ marginBottom: '10px' }}>{this.state.sessionData.SessionYear} {this.state.sessionData.DisplayName}</h2>
                        </div>
                        <div className="details-header">
                            <div>
                                <h3>{this.state.memberData.MemberDisplayName}<span style={{ marginLeft: "0.5em" }}>({this.state.memberData.PartyCode}) - {this.state.memberData.Chamber} District {this.state.memberData.DistrictID}</span></h3>
                            </div>
                            <div className="list-links" style={{ padding: '5px 10px' }}>
                                <Link to={'/session-details/' + this.props.match.params.sessioncode + '/member-information/' + this.props.match.params.membernumber + '/member-details'}>Information</Link>
                                <Link to={'/vote-search/' + this.props.match.params.sessioncode + '/' + this.props.match.params.membernumber}>Votes</Link>
                            </div>
                        </div>
                        {this.state.noResults && <p>No Legislation</p>}
                    </div>
                }
                {this.state.results.length > 0 &&
                    <h4 style={{ margin: "10px 0 10px 0" }}>Legislation as Chief Patron</h4>
                }
                {this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR) && this.state.results.length > 0 &&
                    <div className="dlas-forms collection-controls" style={{ marginBottom: "5px" }}>
                        <h4 style={{ margin: 0 }}>Add bills to one or more watchlists</h4>
                        <div className="inner-grid two">
                            <div>
                                <label htmlFor="sr-watchlist-name-control">Watchlist Name</label>
                                <CreatableSelect
                                    id="sr-watchlist-name-control"
                                    styles={selectStyle}
                                    options={this.state.collections}
                                    getOptionLabel={opt => opt.label || `${opt.Name} ${opt.Description && `- ${opt.Description}`}`}
                                    getOptionValue={opt => opt.value || opt.WatchListID}
                                    value={this.state.selectedWatchlists}
                                    onChange={this.handleWatchlistChange}
                                    createOptionPosition="first"
                                    formatCreateLabel={input => <span>Create new: {input}</span>}
                                    isMulti
                                />
                            </div>
                            <div className="inline-list">
                                <br />
                                {this.props.collectionIsSaving ?
                                    <div><span className="small-spinner"></span></div>
                                    :
                                    <React.Fragment>
                                        <button disabled={this.state.selectedWatchlists.length === 0} style={{ marginLeft: "0px" }} className="button" type="button" onClick={() => this.handleAddToWatchlists()}>Add</button>
                                    </React.Fragment>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div style={{ marginTop: "10px" }}>
                    {this.state.results.map((bill, i) => {
                        return <BillInfoComponent
                            key={i}
                            ref={this._billResultRefs[i]}
                            userCanUseCollections={this.props.login.userClaims.resources.find(resource => resource === BILL_COLLECTION_AUTHOR) ? true : false}
                            bill={bill}
                            billIndex={i}
                            sessionID={this.props.session.sessionList.find(x => x.SessionCode === this.props.match.params.sessioncode).SessionID}
                            toggleShowData={this.toggleShowData}
                            expanded={this.state[bill.LegislationID]}
                            sessionCode={this.props.match.params.sessioncode}
                            selectedCollection={undefined}
                            handleCollectionChange={() => { }}
                            collectionIsSaving={() => { }}
                            handleBillCheckbox={e => this.handleBillCheckbox(i, e)}
                            checked={this.state.checkedBills[i]}
                            selectedKeywords={[]}
                            selectedLocation={undefined}
                            searchMade={false}
                            session={this.props.session.sessionList.find(x => x.SessionID === bill.SessionID)}
                            displayCrossSession={false}
                            newSession={false}
                            displayChapterNumber={false}
                            compositeView={false}
                        />
                    })}
                </div>
            </>
        )
    }

}

export default connect(
    (state) => {
        const { bills, collection, login, members, nav, session } = state;
        return {
            bills,
            collection,
            login,
            members,
            nav,
            session
        }
    },
    (dispatch) => {
        return {
            actions: bindActionCreators(Object.assign({}, sessionActionCreators, memberActionCreators, billActionCreators, collectionActionCreators, navActionCreators), dispatch)
        }
    }
)(PublicMemberLegislation);